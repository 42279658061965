export function cpfRemoveMask(cpf: string) {
  let cpfWithoutMask = '';
  cpfWithoutMask = cpf.replace('.', '');
  cpfWithoutMask = cpfWithoutMask.replace('.', '');
  cpfWithoutMask = cpfWithoutMask.replace('-', '');

  return cpfWithoutMask;
}

export function cpfMask(v: string) {
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
  //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
  return v;
}

export function cnpjWithoutMask(cnpj: string) {
  let cnpjWithoutMask = '';
  cnpjWithoutMask = cnpj.replace('.', '');
  cnpjWithoutMask = cnpjWithoutMask.replace('.', '');
  cnpjWithoutMask = cnpjWithoutMask.replace('/', '');
  cnpjWithoutMask = cnpjWithoutMask.replace('-', '');

  return cnpjWithoutMask;
}

export function cnpjMask(cnpj: string) {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export function formatNumber(data: number) {
  return new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(
    data
  );
}

export function formatCurrency(data: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(data);
}

export function formatCurrencyWithoutSymbol(data: number) {
  const currency = {
    style: 'currency',
    currency: 'BRL',
    currencyDisplay: 'none',
  };
  // let currency = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, }).format(data);

  const stripSymbols = currency.currencyDisplay === 'none';
  const localFormat = stripSymbols
    ? { ...currency, currencyDisplay: 'code' }
    : currency;
  let result = Intl.NumberFormat('pt-BR', localFormat).format(data);
  if (stripSymbols) {
    result = result.replace(/[a-z]{3}/i, '').trim();
  }

  return result;
}

export function getFuel(fuel: string) {
  switch (fuel) {
    case 'GASOLINE':
      return 'Gasolina';

    case 'GASOLINE_ADDITIVE':
      return 'Gasolina Aditivada';

    case 'ETHANOL':
      return 'Etanol';

    case 'ETHANOL_ADDITIVE':
      return 'Etanol Aditivado';

    case 'DIESEL':
      return 'Diesel';

    default:
      return fuel;
  }
}

export function formatFuelToSend(fuel: string) {
  switch (fuel) {
    case 'Gasolina':
      return 'GASOLINE';

    case 'Gasolina Aditivada':
      return 'GASOLINE_ADDITIVE';

    case 'Etanol':
      return 'ETHANOL';

    case 'Etanol Aditivado':
      return 'ETHANOL_ADDITIVE';

    case 'Diesel':
      return 'DIESEL';

    default:
      return fuel;
  }
}

export function getMonth(type: string, monthNumber: number) {
  let monthNames: any[] = [];
  if (type == 'Full') {
    monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
  } else {
    monthNames = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
  }

  return monthNames[monthNumber - 1];
}

export function getPaymentMethod(paymentMethod: string) {
  switch (paymentMethod) {
    case 'MONEY':
      return 'Dinheiro';
    case 'PIX':
      return 'PIX';
    case 'DEBIT':
      return 'Cartão - Débito';
    case 'CREDIT':
      return 'Cartão - Crédito';
    case 'CASHBACK':
      return '100% Cashback';
    default:
      return paymentMethod;
  }
}

export function getInitialsFromName(name: string) {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function getRouteRole() {
  return localStorage.getItem('ROLE');
}

export function phoneRemoveMask(phone: string) {
  let phoneWithoutMask = '';
  phoneWithoutMask = phone.replace('(', '');
  phoneWithoutMask = phoneWithoutMask.replace(')', '');
  phoneWithoutMask = phoneWithoutMask.replace('-', '');
  phoneWithoutMask = phoneWithoutMask.replace('+', '');
  phoneWithoutMask = phoneWithoutMask.replace(' ', '');
  return phoneWithoutMask;
}

export function formatReal(int: any) {
  let v = int + '';
  if (v.length == 1) {
    v = v + '00';
  } else if (v.length == 3) {
    v = v + '0';
  }
  v = v.replace(/\D/g, ''); // permite digitar apenas numero
  v = v.replace(/(\d{1})(\d{14})$/, '$1.$2'); // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{11})$/, '$1.$2'); // coloca ponto antes dos ultimos 13 digitos
  v = v.replace(/(\d{1})(\d{8})$/, '$1.$2'); // coloca ponto antes dos ultimos 10 digitos
  v = v.replace(/(\d{1})(\d{5})$/, '$1.$2'); // coloca ponto antes dos ultimos 7 digitos
  v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2'); // coloca virgula antes dos ultimos 4 digitos

  return v;
}

export function formatCents(value) {
  const str = value + '';
  const arr = str.split('');
  if (arr.length == 3) {
    return arr[0] + arr[1] + arr[2] + ',' + '00';
  } else if (arr.length == 4) {
    return arr[0] + arr[1] + arr[2] + ',' + '00';
  } else if (arr.length == 5) {
    return arr[0] + arr[1] + arr[2] + ',' + arr[4] + '0';
  } else if (arr.length == 6) {
    return value;
  }
}

export function removeCurrency(data: any) {
  if (data.length >= 4) {
    let remove = data.replace('.', '');
    remove = remove.replace(',', '.');
    return remove;
  } else {
    return data;
  }
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function removeCookie(cname: string) {
  if (getCookie(cname)) {
    setCookie(cname, null, -1);
  }
}

export function setCookie(
  cname: string,
  cvalue: string | null,
  exdays: number | Date
) {
  const d = new Date(exdays);
  // d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function formatName(name: any) {
  const nameArr = name.trim().split(' ');

  if (nameArr.length > 1) {
    const firstName =
      nameArr[0].charAt(0).toUpperCase() + nameArr[0].slice(1).toLowerCase();

    const lastName =
      nameArr.at(-1).length > 0
        ? nameArr.at(-1).charAt(0).toUpperCase() +
          nameArr.at(-1).slice(1).toLowerCase()
        : nameArr.at(-2).charAt(0).toUpperCase() +
          nameArr.at(-2).slice(1).toLowerCase();

    const formattedName = firstName + ' ' + lastName;
    return formattedName;
  } else {
    return name;
  }
}

export function formatFullName(name: any) {
  return name.toLocaleLowerCase().split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}


export function returnRole(role: string) {
  const roleLower = role.toLowerCase();

  if (roleLower == 'attendant') {
    return 'Frentista';
  } else if (roleLower == 'admin') {
    return 'Administrador';
  } else if (roleLower == 'client') {
    return 'Cliente';
  } else if (roleLower == 'manager') {
    return 'Gerente';
  } else if (roleLower == 'partner') {
    return 'Parceiro';
  }
}
