import MethodologyModel from "../Model/MethodologyModel";

const methodologyModel = new MethodologyModel();

export default class MethodologyController {
  public async getMethodology() {
    return await methodologyModel
      .getMethodology()
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  public getMethodologyById(id) {
    return methodologyModel
      .getMethodologyById(id)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        return error;
      });
  }

  public async setMethodology(data) {
    return await methodologyModel.setMethodology(data);
  }
}
