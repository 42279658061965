
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import { Options, Vue } from "vue-class-component";
import CatalogController from "@/AppClubCarby/Controller/CatalogController";

import { getInitialsFromName } from "@/mixins";

@Options({
  components: {
    CardComponent,
  },
  created() {
    this.getCatalogs();
  },
  data() {
    return {
      PartnerListStore: [],
      filter: "",
      catalogController: new CatalogController(),
    };
  },

  computed: {
    searchStore() {
      if (this.filter) {
        let exp = new RegExp(this.filter.trim(), "i");
        return this.PartnerListStore.filter(
          (store: any) => exp.test(store.Name) || exp.test(store.Address)
        );
      } else {
        return this.PartnerListStore;
      }
    },
  },

  methods: {
    getImgUrl(img: string) {
      var images = require.context(
        "../../../../assets/stores-img",
        false,
        /\.png$/
      );
      return images("./" + img + ".png");
    },
    getCatalogs() {
      this.catalogController._getCatalogs().then((catalog: any) => {
        this.PartnerListStore = catalog.data.content.map((item: any) => {
          return {
            Img:
              item.logo == "" || item.logo == null ? "withoutImage" : item.logo,
            Name: item.fantasyName,
            Address: `${item.address.street} n. ${item.address.number}, ${item.address.city}-${item.address.uf}`,
          };
        });
      });
    },
    returnInitials(name: string) {
      return getInitialsFromName(name);
    },
  },
})
export default class PartnerList extends Vue {}
