<template>
  <transition :name="transitionEffect">
    <CardComponent
      class="testimonial-card"
      v-show="currentCardSlide === index"
      @mouseenter="$emit('mouseenter')"
      @mouseout="$emit('mouseout')"
    >
      <h2>{{ testimonial.title }}</h2>
      <p>{{ testimonial.content }}</p>
      <div class="user-info">
        <div>
          <img
            :src="getImgUrl(testimonial.userPhoto)"
            :alt="testimonial.title"
          />
        </div>
        <div>
          <h3 class="user-name">{{ testimonial.userName }}</h3>
          <p class="user-profession">{{ testimonial.userProfession }}</p>
        </div>
      </div>
    </CardComponent>
  </transition>
</template>

<script>
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
export default{
    components:{CardComponent},
emits:['mouseenter','mouseout'],
props:["testimonial", "currentCardSlide","index","direction"],
methods:{
    getImgUrl(img){
  var images = require.context(
    "../../../assets/landing-page/testimonials-img",
    false,
    /\.svg$/
  );
  return images("./" + img + ".svg");
}
},
computed:{
    transitionEffect(){
        return this.direction === "rigth" ? "slide-out" : "slide-in";
    }
}

}

</script>

<style lang="scss" scoped>
@import "./CarouselCardItem.scss";
</style>
