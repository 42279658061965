import CarbonModel from "../Model/CarbonModel";

const carbonModel = new CarbonModel();

export default class CarbonController {
  public async getCarbonPriceById(id) {
    return await carbonModel
      .getCarbonPrice(id)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  public getCarbonHistoric() {
    return carbonModel
      .getCarbonHistoric()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  public async setCarbonHistoric(data) {
    return await carbonModel.setCarbonHistoric(data);
  }

  public async getCarbonLastItem() {
    return await carbonModel
      .getCarbonLastItem()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  public async setCarbonPrice(id, data) {
    return await carbonModel.setCarbonPrice(id, data);
  }
}
