import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default class CarbonModel {
  public getCarbonPrice(id) {
    return axios.get(`${API_URL}/carbon-sale-price/${id}`);
  }

  public getCarbonHistoric() {
    return axios.get(`${API_URL}/carbon-sale-price/page`);
  }

  public getCarbonLastItem() {
    return axios.get(`${API_URL}/carbon-sale-price/last`);
  }

  public setCarbonHistoric(data) {
    return axios.post(`${API_URL}/carbon-sale-price`, data);
  }

  public setCarbonPrice(id, data) {
    return axios.put(`${API_URL}/carbon-sale-price/${id}`, data);
  }
}
