
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import MenuLanding from '@/LandingPage/Components/MenuLanding/MenuLanding.vue';
// import Copyright from "@/components/Copyright/Copyright.vue";
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from '@vuelidate/validators';
import textTerms from '@/assets/json/terms.json';
import textPolitics from '@/assets/json/politics.json';

import UserController from '@/AppClubCarby/Controller/UserController';
import { SetUserModel } from '@/models/UserModel';
import Swal from 'sweetalert2';
import ModalComponent from '@/LandingPage/Components/ModalComponent/ModalComponent.vue';
import Footer from '@/LandingPage/Components/Footer/Footer.vue';
import GasStationController from '@/AppClubCarby/Controller/GasStationController';

@Options({
  components: {
    MenuLanding,
    ModalComponent,
    Loading,
    Footer,
  },
  setup() {
    return {};
  },

  created() {
    if (this.$route.params.id) {
      this.inviteCode = this.$route.params.id;
    }

    this.getGasStations();
    this.firstAccess = true;
  },
  data() {
    return {
      noEmail: false,
      isLoading: false,
      gasStationController: new GasStationController(),
      v$: useVuelidate(),
      userController: new UserController(),
      user: new SetUserModel(),
      errorEmail: false,
      cpfWithoutMask: '',
      invalidPass: '',
      invalidCPF: '',
      confirmEmail: null,
      confirmPass: null,
      favoriteGasStations: { id: null },
      modalActive: false,
      modalActivePrivacy: false,
      terms: false,
      privacy: false,
      textTermsReturn: textTerms,
      textPoliticsReturn: textPolitics,
      inviteCode: null,
      gasStations: [],
      firstAccess: false,
      searchButton: true,
      cpfError: 0,
    };
  },
  validations() {
    return {
      confirmPass: {
        required,
        sameAs: sameAs(this.user.user.password),
      },
      confirmEmail: {
        required,
        email,
        sameAs: sameAs(this.user.user.email),
      },
      user: {
        user: {
          name: { required },
          cpf: { required },
          email: { required, email },
          password: {
            required,
            minLength: minLength(6),
            maxLength: maxLength(20),
          },

          phone: {
            required,
          },
        },
      },
      favoriteGasStations: {
        id: { required },
      },
    };
  },
  methods: {
    searchUser() {
      this.isLoading = true;
      let cpf = this.user.user.cpf;
      this.userController
        .pajetPreRegister(cpf)
        .then((data) => {
          this.isLoading = false;

          if (data.data.name) {
            this.user.user.name = data.data.name;
          } else {
            if (this.cpfError < 3) {
              this.cpfError++;
              Swal.fire({
                title: 'Ooops!',
                text: 'Usuário não encontrado. Favor verificar o CPF digitado!',
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            } else {
              this.searchButton = false;
              Swal.fire({
                title: 'Ooops!',
                text: 'Talvez seu CPF não esteja no nosso banco de dados. Por favor preencha todas as informações de cadastro',
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            }
          }

          if (data.data.email) {
            this.user.user.email = data.data.email;
          }

          if (data.data.phone) {
            let phoneNumber = data.data.phone
              .toString()
              .split('')
              .slice(3)
              .toString()
              .replace(/,/g, '');

            console.log(phoneNumber);
            this.user.user.phone = phoneNumber;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (this.cpfError < 3) {
            this.cpfError++;
            Swal.fire({
              title: 'Ooops!',
              text: 'Usuário não encontrado. Favor verificar o CPF digitado!',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          } else {
            this.searchButton = false;
            Swal.fire({
              title: 'Ooops!',
              text: 'Talvez seu CPF não esteja no nosso banco de dados. Por favor preencha todas as informações de cadastro',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          }
        });
    },
    setUser() {
      if (
        this.v$.user.user.email.$error &&
        this.v$.confirmEmail.$error &&
        this.v$.$errors.length <= 3
      ) {
        this.noEmail = true;
      } else {
        this.noEmail = false;
      }
      if (this.user.favoriteGasStations.length === 0) {
        this.user.favoriteGasStations.push(this.favoriteGasStations);
      }

      this.v$.$touch();
      if (this.v$.$error) {
        return;
      } else {
        if (this.terms && this.privacy) {
          this.cpfRemoveMask();
          this.user.user.address.surname = `Endereço ${this.user.user.name}`;
          if (this.user.acceptedTerms.length === 0) {
            this.user.acceptedTerms.push(
              'terms_of_use',
              'privacy_and_data_protection_policy'
            );
          }

          this.isLoading = true;

          this.user.user.phone = this.removePhoneMask(this.user.user.phone);
          this.user.inviteCode = this.inviteCode;

          this.userController
            .confirmPreRegister(this.user)
            .then(() => {
              this.isLoading = false;
              Swal.fire({
                title: 'Yay!',
                text: 'Usuário cadastrado com sucesso!',
                icon: 'success',
                confirmButtonText: 'Ok',
              });

              this.v$.$reset();
              this.user = new SetUserModel();
              this.confirmEmail = null;
              this.confirmPass = null;
            })
            .catch((error: any) => {
              this.isLoading = false;

              let errorMessages = '';

              if (error.response.data.erros) {
                if (error.response.data.erros.usercpf) {
                  errorMessages += error.response.data.erros.usercpf + ' ';
                }

                if (error.response.data.erros.userpassword) {
                  errorMessages += error.response.data.erros.userpassword + ' ';
                }

                if (error.response.data.erros.username) {
                  errorMessages += error.response.data.erros.username + ' ';
                }

                if (error.response.data.erros.userphone) {
                  errorMessages += error.response.data.erros.userphone + ' ';
                }

                this.isLoading = false;

                Swal.fire({
                  title: 'Oops!',
                  text: errorMessages,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                });
              }

              if (error.response.data.erros) {
                this.isLoading = false;

                Swal.fire({
                  title: 'Oops!',
                  text: errorMessages,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                });
              } else if (this.user.user.acceptedTerms.length < 2) {
                Swal.fire({
                  title: 'Oops!',
                  text: 'Para se cadastrar é preciso aceitar os termos de uso e privacidade da plataforma',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                });
              }
            });
        } else {
          this.isLoading = false;
        }
      }
    },
    cpfRemoveMask: function () {
      this.cpfWithoutMask = this.user.user.cpf.replace('.', '');
      this.cpfWithoutMask = this.cpfWithoutMask.replace('.', '');
      this.cpfWithoutMask = this.cpfWithoutMask.replace('-', '');

      this.user.user.cpf = this.cpfWithoutMask;
    },
    showModalTerms() {
      setTimeout(() => {
        this.modalActive = !this.modalActive;
      }, 0);
    },
    showModalPrivacy() {
      setTimeout(() => {
        this.modalActivePrivacy = !this.modalActivePrivacy;
      }, 0);
    },
    setPhone(telefone: any) {
      let v = telefone.target.value + '';

      v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos

      this.user.user.phone = v;
    },
    removePhoneMask(phone: string) {
      phone = phone.replace('-', '');
      phone = phone.replace('(', '');
      phone = phone.replace(')', '');
      phone = phone.replace(' ', '');

      if (phone.startsWith('55')) {
        return phone;
      } else {
        return '55' + phone;
      }
    },
    getGasStations() {
      this.gasStationController._getGasStation().then((item) => {
        this.gasStations = item.data.content;
      });
    },
    infoButtons(info: string) {
      this.firstAccess = false;
      if (info == 'agree') {
        this.searchButton = true;
      } else {
        this.searchButton = false;
      }
    },
    setFakeEmail() {
      this.noEmail = !this.noEmail;
      if (!this.user.user.email) {
        let name = this.user.user.name.split(' ')[0].toLowerCase();
        name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        let cpf = this.user.user.cpf.split('.')[0];
        let email;

        email = `${name}.${cpf}@yopmail.com`;
        this.user.user.email = email;
        this.confirmEmail = email;
      }
    },
    toggleEmailModal(info) {
      if (info == 'open') {
        this.noEmail = true;
      } else {
        this.noEmail = false;
      }
    },
  },
})
export default class PreSignUp extends Vue {}
