import { SetCatalogModel } from "@/models/CatalogModel";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default class CatalogController {
  private _setCatalog(catalog: SetCatalogModel) {
    return axios.post(`${API_URL}/catalogPartner`, catalog);
  }

  public _getCatalogs() {
    return axios.get(`${API_URL}/catalogPartner/page`);
  }

  public getCatalogPartnerById(id) {
    return axios.get(`${API_URL}/catalogPartner/${id}`);
  }

  public _getCatalogPartnerByPartnerId(partnerId: number) {
    return axios.get(`${API_URL}/catalogPartner/partnerId/${partnerId}`);
  }

  private _updateCatalog(catalog: any) {
    return axios.put(`${API_URL}/catalogPartner/`, catalog);
  }

  public getNetworks(){
    return axios.get(`${API_URL}/network/page?page=0&linesPerPage=24&direction=DESC&orderBy=id`);
  }
}
