import store from "@/store";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default class MonthlyPaymentModel {
  public getMonthlyPaymentById(id) {
    return axios.get(`${API_URL}/monthly-payment/${id}`);
  }

  public getMonthlyParametersByGasStationId(gasStationId) {
    return axios.get(`${API_URL}/monthly-payment/gas_station/${gasStationId}`);
  }

  public getParametersByGasStationId(gasStationId: number) {
    return axios.get(
      `${API_URL}/monthly-payment/parameters/gas_station/${gasStationId}`
    );
  }

  public getHistoric() {
    return axios.get(`${API_URL}/monthly-payment/page`);
  }

  public setPaymentById(id) {
    return axios.put(`${API_URL}/monthly-payment/${id}`);
  }

  public setPay(id, data) {
    return axios.put(`${API_URL}/monthly-payment/pay/${id}`, data);
  }
}
