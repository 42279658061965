<template>
    <div>
        <div class="contract">
            <div class="body-contract">
                <p>Bem vindo às Condições Gerais de Uso da CARB43 SOLUÇÕES EM ENGENHARIA DE
                    CARBONO LTDA, nome fantasia CARBY, (Condições Gerais).<br/>
                    O presente documento visa esclarecer, da forma mais simples possível, sobre seus direitos e
                    obrigações no ambiente de nossa plataforma.</p>

                <p>O presente documento indica, entre outras coisas:</p>

                <ol>
                    <li>Os direitos e obrigações das pessoas que contratam a CARBY através dos canais de informação
                        disponíveis na frontpage, antes da contratação.</li>
                    <li>Os direitos e obrigações da sua empresa (titular) e da pessoa designada para manusear a
                        plataforma (usuário), após a contratação. </li>
                    <li>Os direitos, obrigações e autorizações concedidas ao CARBY.</li>
                    <li>As regras de prestação do serviço.</li>
                    <li>As limitações da prestação do serviço.</li>
                </ol>

                <p>As pessoas envolvidas nestas Condições Gerais são:</p>

                <ol>
                    <li>A CARBY, pessoa jurídica que presta serviços e exerce os papéis de controladora e operadora dos
                        dados inseridos na plataforma (CARBY).</li>
                    <li>A pessoa jurídica (posto de combustível) que assina o termo de convênio com a plataforma;</li>
                    <li>A pessoa física (motorista) que acessa e utiliza a plataforma</li>
                </ol>

                <p>A prestação dos nossos serviços é regulada por um Contrato entre as partes (Termo de uso), integrado
                    pelas presentes Condições Gerais, pela Política de Privacidade, e, quando houver, pelo Termo de
                    Prestação de Serviços com a CARBY (Termo de Serviços).<br/>
                    As Condições Gerais e a Política de Privacidade estarão sempre disponíveis na sítio eletrônico da
                    CARBY (plataforma).<br/>
                    Para utilizar os nossos serviços, é preciso que o titular e o usuário concordem e possam cumprir
                    todos os itens estabelecidos no Contrato. Se não houver concordância com os termos do Contrato, o
                    titular e os usuários indicados não devem acessar a plataforma, e contatar-nos em um dos canais para
                    eventual adequação da plataforma.</p>

                <h5>Quem somos?</h5>

                <p>Somos uma empresa que busca a inovação; demos origem a um produto que materializa a primeira
                    ferramenta que permite abastecer compensando impacto ambiental. Devido a essa invenção, fomos
                    vencedores de um concurso de empreendedorismo em Londres-Reino Unido, em 2020. O ClubCarby, desde
                    2020, vem provando que o conceito de agregar sustentabilidade aos postos de combustíveis não pode
                    ser simplista. Acreditamos que o movimento mais sustentável é aquele que vem da base da pirâmide. Ou
                    seja, no caso dos combustíveis, um movimento que se inicia do revendedor, o dono de posto mesmo,
                    direto aos seus clientes fiéis. Do jeito ClubCarby.
                </p>

                <h5>Sobre nossos serviços</h5>

                <p>A CARBY é uma plataforma que busca gerenciar a pegada de carbono no ato de abastecimento de
                    automóveis, ao mesmo tempo em que traz a fidelização sustentável da clientela e melhoria de
                    resultados para postos de combustíveis. O ClubCarby, clube de benefícios que materializa os
                    propósitos da CARBY, é um clube de motoristas e postos de combustíveis conscientes que, a cada
                    abastecimento, somam Cashback e compensação de suas pegadas de carbono.<br/>
                    A compensação de carbono e o cashback são ofertados pelos postos parceiros, e a CARBY atua para
                    facilitar a interface entre os postos, o mercado de créditos de carbono e os motoristas.</p>

                <h5>Limitações dos nossos serviços</h5>

                <p>A CARBY é uma plataforma que faz a intermediação dos créditos de carbono, não atuando ela própria na
                    neutralização dos gases de efeito estufa (GEE) e pegadas de carbono causadas pelos combustíveis
                    fósseis. Os usuários devem ter claro que os créditos de carbono não são gerados pela atuação direta
                    da CARBY e que a atuação da plataforma consiste em realizar a compensação por meio de empresas
                    parceiras e certificadas, denominadas entidades de certificação.</p>

                <h5>Visitantes</h5>

                <p>Os visitantes que se cadastrarem na plataforma têm assegurados os direitos previstos nestas Condições
                    Gerais e na Política de Privacidade da Plataforma, no que couber.
                    Ao se cadastrar na plataforma, através dos formulários de contato, o visitante autoriza à CARBY
                    utilizar-se dos dados para contatá-lo e encaminhar as informações comerciais solicitadas.
                </p>

                <h5>Contratação</h5>

                <p>Existem duas modalidades de contratação, a depender de se tratar dos postos de combustíveis ou dos
                    motoristas.</p>

                <ol>
                    <li>No caso dos postos de combustível, o primeiro passo é agendar uma reunião para esclarecer
                        eventuais dúvidas e simular um orçamento. Em seguida, assinatura do contrato. O terceiro passo é
                        a integração do sistema ClubCARBY com a concentradora do posto, para, por fim, proceder ao
                        treinamento do time para utilização da plataforma.</li>
                    <li>No caso dos motoristas, o primeiro passo é fazer o cadastro no ClubCarby e localizar os postos
                        conveniados. Ao realizar o abastecimento, informar o CPF ao frentista para registrar o
                        abastecimento. O ClubCarby calculará e compensará automaticamente parte das emissões. Ao
                        abastecer, o cliente pode ganhar cashback para futuros abastecimentos.</li>
                </ol>

                <h5>Alterações no Termos</h5>

                <p>A Carby reserva-se o direito de realizar alterações nas Condições Gerais e na Política de
                    Privacidade, por razões legais ou regulamentares, para a melhoria do serviço (por meio de
                    implementação de avanços e ajustes técnicos) e para a garantia do funcionamento e da segurança do
                    serviço.<br/>
                    Eventual alteração nas Condições Gerais e na Política de Privacidade será comunicada com
                    antecedência, por e-mail e notificações proeminentes na plataforma, e a continuidade do uso da
                    plataforma constituirá concordância com as alterações propostas. Caso não haja interesse na
                    continuidade dos serviços, deve-se deixar de usar nossos serviços, na forma prevista nas Condições
                    Gerais ou no Termo de Serviços.</p>

                <h5>Deveres e proibições ao titular e usuário</h5>

                <p>Os titulares dos dados (motoristas) e os usuários das plataformas (postos de combustíveis) devem:</p>

                <ol>
                    <li>Se desejarem utilizar os serviços do CARBY, aceitar integralmente o disposto no Contrato, que é
                        composto pelas Condições Gerais, pela Política de Privacidade e pelo Termo de Serviços.</li>
                    <li>prestar as informações necessárias para o cadastro do titular e do usuário na plataforma,
                        descritos na Política de Privacidade, e mantê-las atualizadas.</li>
                    <li>somente fornecer à plataforma informações das quais seja o titular ou possa legitimamente
                        utilizar.</li>
                    <li>guardar as informações de acesso (login e senha), sob sua guarda.</li>
                    <li>Indicar, para operar a plataforma, usuário com poderes e autorização para dar consentimento,
                        licenciar, manipular, acessar, visualizar, obter conhecimento sobre os dados fornecidos à
                        plataforma e o seu conteúdo, e todos os demais poderes necessários para o uso da plataforma</li>
                    <li>Cientificar a todos os envolvidos sobre o conteúdo das Condições Gerais e a Política de
                        Privacidade da plataforma.</li>
                    <li>cumprir outras obrigações previstas no Contrato, inclusive financeiras.</li>
                </ol>

                <p>É proibido ao titular e ao usuário:</p>

                <ol>
                    <li>fornecer ou empregar qualquer informação falsa ou errônea à plataforma, ou cuja procedência não
                        puder ser verificada.</li>
                    <li>fornecer ou empregar qualquer informação de terceiros, com ou sem autorização daquele, para fins
                        de cadastro, contratação, utilização ou qualquer outro dentro da plataforma.</li>
                    <li>cadastrar-se ou acessar a plataforma em nome de terceiros.</li>
                    <li>fornecer informações de origem ilegal, cuja divulgação viole o sigilo fiscal, direitos autorais,
                        propriedade intelectual, privacidade, propriedade intelectual ou direitos de terceiros.</li>
                    <li>utilizar-se do serviço para fins contrários ao direito de terceiros, ao Contrato entre as partes
                        e à ordem jurídica em geral.</li>
                    <li>divulgar o conteúdo da plataforma a terceiros, ou vender, alugar, ceder, licenciar ou permutar o
                        serviço ou o conteúdo da plataforma com terceiros.</li>
                    <li>preencher os campos de texto com termos, expressões ou materiais que contrariem a lei,
                        agressivos, caluniosos, abusivos, difamatórios, racistas, obscenos, violadores de direitos de
                        terceiros, de publicidade, propaganda ou que atentem contra o decoro ou a ordem pública.</li>
                    <li>realizar engenharia reversa, descompilar, desmontar ou modificar a plataforma, ou criar
                        tecnologia que permita contornar as ferramentas para proteção do conteúdo e do serviço.</li>
                    <li>contornar restrições territoriais à prestação do serviço estabelecidas pela CARBY.</li>
                    <li>encaminhar, através da plataforma ou qualquer outro meio, conteúdo malicioso de qualquer
                        natureza, como vírus, malware, cavalos de tróia.</li>
                    <li>utilizar-se de qualquer artifício ou meio, ferramenta de software ou hardware, ou qualquer outro
                        instrumento com o propósito de interferir, interromper, desvirtuar, sondar, varrer, testar a
                        vulnerabilidade da plataforma e sua infra-estrutura.</li>
                    <li>agir de forma contrária a qualquer item das Condições Gerais, da Política de Privacidade e do
                        Termo de Prestação de Serviços.</li>
                </ol>

                <p>Além de outras sanções previstas no Contrato, a prática dos atos proibidos pelo usuário autoriza a
                    rescisão unilateral do contrato, com efeitos imediatos.
                </p>

                <h5>Conteúdo do titular</h5>

                <p>O titular ou o usuário podem enviar, carregar e inserir conteúdo produzido por si (Conteúdo do
                    Titular) para utilizar o serviço disponibilizado, seja através do preenchimento manual ou do envio
                    de arquivos em formato apropriado, por intermédio dos canais destinados para esta atividade na
                    plataforma.</p>

                <p>
                    O Conteúdo do Titular é licenciado à CARBY quando carregado na plataforma, de forma irrevogável, e
                    poderá ser utilizado na forma do Contrato.
                    A CARBY se reserva o direito de remover ou desabilitar qualquer Conteúdo do Titular em desacordo com
                    o Contrato, a seu critério exclusivo.
                </p>

                <h5>Dados fornecidos à Carby</h5>

                <p>Os dados do titular e do usuário nunca serão utilizados de forma que seja possível a terceiros
                    identificá-los, obter informações comerciais, fiscais ou sigilosas de qualquer natureza, na forma
                    descrita na Política de Privacidade.<br/>
                    Os dados do titular e do usuário, integrarão o banco de dados da CARBY, que poderá utilizá-los para
                    o cumprimento de obrigações legais, execução do contrato, exercício regular de direito e de
                    interesses legítimos da CARBY, na forma descrita na Política de Privacidade, sendo submetidos,
                    sempre que necessário, a processo técnico de anonimização, que garanta a privacidade do titular e do
                    usuário.<br/>
                    O controle dos dados do titular e do usuário nunca será cedido a terceiros.<br/>
                    Os dados fornecidos pelo usuário na plataforma serão tratados conforme a Política de Privacidade e
                    Proteção aos Dados da CARBY, a Lei Geral de Proteção de Dados (Lei 13.709/2018), o Código Civil e
                    leis e regulamentos aplicáveis.</p>

                <h5>Direitos do titular e do usuário</h5>

                <p>São direitos do titular e do usuário:</p>

                <ol>
                    <li>Ter seus dados tratados na forma descrita na Política de Privacidade.</li>
                    <li>Acessar e utilizar a plataforma na forma do Termo de Serviços.</li>
                    <li>A prestação dos serviços contratados na forma contratada.</li>
                </ol>

                <h5>Direitos da Carby</h5>

                <p>Para permitir a execução ou como contraprestação pelos serviços da CARBY, esta possui o direito a:
                </p>

                <ol>
                    <li>utilizar hardware de processador, banda larga e armazenamento do seu dispositivo e instalar
                        cookies para facilitar a operação do Serviço.</li>
                    <li>exibir publicidade e outras informações na plataforma.</li>
                </ol>

                <h5>Garantia</h5>

                <p>O Serviço e o Conteúdo do CARBY são ofertados “como estão” e “conforme disponíveis”, sem a oferta de
                    qualquer garantia explícita ou implícita. A finalidade do serviço prestado é informativa, o conteúdo
                    da plataforma não constitui conselho, tampouco gera direito ou expectativa de direitos entre o
                    usuário, a CARBY e/ou terceiros.
                    A CARBY não se responsabiliza por eventuais dificuldades de acesso aos serviços causadas por
                    terceiros, como por exemplo e não somente, provedor de internet, concessionários de energia
                    elétrica, etc.<br/>
                    A CARBY não garante que a plataforma seja livre de vírus e outras formas de malware, nem a prestação
                    contínua e ininterrupta do serviço. Eventuais interrupções programadas para a atualização do sistema
                    serão previamente comunicadas por meio de notificação proeminente na plataforma, e ocorrerão
                    preferencialmente fora do horário comercial, compreendido aqui como o período das 9h às 18h, de
                    segunda a sexta-feira.<br/>
                    A CARBY não se responsabiliza pelo conteúdo de terceiros, inclusive publicitário, vinculado na
                    plataforma.</p>

                <h5>Interpretação</h5>

                <p>O Contrato entre as partes é composto pelas presentes Condições de Uso, pela Política de Privacidade
                    e, quando houver, pelo Termo de Prestação de Serviços.<br/>
                    O acesso à plataforma constitui declaração de aceitação das Condições de Uso da Plataforma e da
                    Política de Privacidade.
                    Em caso de conflito entre as Condições Gerais, a Política de Privacidade e o Termo de Prestação de
                    Serviços, prevalecerá as condições particulares previstas neste último, assegurados sempre os
                    direitos de terceiros.<br/>
                    As partes declaram que agirão conforme a boa-fé, e que, silente o Contrato, a relação jurídica entre
                    as partes reger-se-á pelo Código Civil (Lei 10.406/2002).</p>

                <h5>Foro da Eleição</h5>

                <p>Para dirimir qualquer dúvida a respeito do presente documento, fica eleito o Foro da Comarca de
                    Vitória - Espírito Santo.</p>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
@import './ModalContracts.scss';
</style>